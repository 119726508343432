import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const FormElem = (props) => {
  let errorMessage = false;
  if (props.errors) {
    props.errors.forEach((error) => {
      if (error.error_id === "-ve-" + props.name) {
        errorMessage = error.message;
      }
    });
  }

  return (
    <div className="form-control-holder">
      {errorMessage && <div className="text-white p-2">{errorMessage}</div>}
      {props.type !== "textarea" ? (
        <input
          className={"mb-4 form-control " + (errorMessage ? "border-red" : "")}
          name={props.name}
          id={props.name}
          type={props.type}
          value={props.value}
          onChange={props.onChange}
          data-lpignore="true"
          placeholder={props.children}
        />
      ) : (
        <textarea
          className={
            "form-control h-[150px] " + (errorMessage ? "border-red" : "")
          }
          name={props.name}
          id={props.name}
          value={props.value}
          onChange={props.onChange}
          placeholder={props.children}
          rows={props.rows}
        />
      )}
    </div>
  );
};

export default class ContactFormUtil extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Name: "",
      Email: "",
      Phone: "",
      Message: "",
      status: null,
      statusMessage: null,
      errors: null,
      recaptcha: false,
    };

    this.recaptchaRef = React.createRef();
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.state.recaptcha) {
      this.setState({
        status: "error",
        statusMessage: "Recaptcha validation failed. Are you a robot?",
        errors: null,
      });

      return;
    }

    // Get the form element, action & method, as well as body.
    const formElem = event.target,
      { action, method } = formElem,
      body = new FormData(formElem);

    // Send the request to Contact Form 7.
    fetch(action, {
      method,
      body,
    })
      // Convert response to json.
      .then((response) => response.json())
      .then((response) => {
        // response.invalid fields is our errors, otherwise, set this to null.
        const errors = response.invalid_fields ? response.invalid_fields : null;

        // Set state after submitting form.
        this.setState({
          status: response.status,
          statusMessage: response.message,
          errors: errors,
        });

        // Reset needed values if email was sent.
        if (this.state.status === "mail_sent") {
          this.setState({
            Name: "",
            Email: "",
            Phone: "",
            Message: "",
            errors: null,
          });

          this.setState({
            recaptcha: false,
          });
          this.recaptchaRef.current.reset();
        }
      })
      .catch((error) => {
        // This shouldn't happen, but handle it, just in case.
        this.setState({
          status: "error",
          statusMessage:
            "There was an unknown error. Please try contacting us directly instead.",
          errors: null,
        });
      });
  };

  onValidateRecaptcha = (value) => {
    this.setState({
      recaptcha: value,
    });
  };

  render() {
    return (
      <form
        action={`${process.env.WP_URL}/wp-json/contact-form-7/v1/contact-forms/${process.env.WP_CF7_CONTACT_ID}/feedback`}
        method="post"
        onSubmit={this.handleSubmit}
      >
        {this.state.status && (
          <div
            className={
              "mb-4 alert " +
              (this.state.status === "mail_sent"
                ? "alert-success"
                : "alert-danger")
            }
          >
            {this.state.statusMessage}
          </div>
        )}
        <FormElem
          name="Name"
          type="text"
          value={this.state.Name}
          onChange={this.handleInputChange}
          index={0}
          errors={this.state.errors}
        >
          Name*
        </FormElem>
        <FormElem
          name="Email"
          type="email"
          value={this.state.Email}
          onChange={this.handleInputChange}
          index={1}
          errors={this.state.errors}
        >
          Email*
        </FormElem>
        {/* <FormElem
          name="Phone"
          type="tel"
          value={this.state.Phone}
          onChange={this.handleInputChange}
          index={2}
          errors={this.state.errors}
        >
          Phone
        </FormElem> */}
        <FormElem
          name="Message"
          type="textarea"
          rows="5"
          value={this.state.Message}
          onChange={this.handleInputChange}
          index={3}
          errors={this.state.errors}
        >
          Message*
        </FormElem>
        <div className="mt-4">
          <ReCAPTCHA
            sitekey="6LekkC8hAAAAAKVawjWgEX2O--lVqPdGD-NDYmwv"
            onChange={this.onValidateRecaptcha}
            ref={this.recaptchaRef}
          />
        </div>
        <button className="btn btn-secondary mt-4" type="submit">
          Submit
        </button>
      </form>
    );
  }
}
