import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import CmsImage from "../utilities/cms-image";
import Content from "../utilities/content";
import ContactFormUtil from "../utilities/contact-form";
import Link from "../utilities/link";

const Footer = () => {
  const {
    wp: {
      themeSettings: { themeSettings: data },
    },
    footerNav: {
      menuItems: { nodes: footerNavLinks },
    },
  } = useStaticQuery(graphql`
    query Footer {
      wp {
        themeSettings {
          themeSettings {
            content
            copyright

            socialMedia {
              link
              icon {
                altText
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            footerLogoInverted {
              altText
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            map {
              altText
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
      footerNav: wpMenu(slug: { eq: "footer-menu" }) {
        menuItems {
          nodes {
            id
            label
            path
          }
        }
      }
    }
  `);

  const year = new Date().getFullYear();
  data.copyright = data.copyright.replace(/{{ year }}/, year.toString());

  return (
    <footer className="footer" id="footer">
      <div className="container-fluid">
        <div className="row align-items-sm-stretch">
          <div className="col-lg-9 bg-contact align-items-center d-flex">
            <div className="footer-contact-map">
              <div className="row align-items-center">
                <div className="col-lg-4 footer-col mb-5 mb-lg-0">
                  <h4 className="footer-title ">Contact Us</h4>
                  <div className="footer-contact-form">
                    <iframe
                      className="jotform jotform-contact-footer"
                      src="https://form.jotform.com/222163757174155"
                    />
                  </div>
                </div>
                <div className="col-lg-8 footer-col">
                  {data.map && (
                    <CmsImage image={data.map} className="img-fluid" />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 g-0 order-lg-first">
            <div className="footer-content-social">
              <Link to="/">
                <CmsImage
                  className="img-fluid mb-5"
                  image={data.footerLogoInverted}
                />
              </Link>
              <nav className="nav flex-column footer-nav">
                {footerNavLinks &&
                  footerNavLinks.map((link, key) => (
                    <a className="nav-link p-0" href={link.path} key={key}>
                      {link.label}
                    </a>
                  ))}
              </nav>
              <Content className="mb-4">{data.content}</Content>
              <ul className="list-inline footer-social">
                {data.socialMedia &&
                  data.socialMedia.map((link, key) => (
                    <li key={key}>
                      <Link
                        to={link.link}
                        className="social-link"
                        target="_blank"
                      >
                        {link.icon && (
                          <CmsImage image={link.icon} className="img-fluid" />
                        )}
                      </Link>
                    </li>
                  ))}
              </ul>
              <Content className="footer-copyright">{data.copyright}</Content>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
