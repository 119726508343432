import React, { useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import "../../styles/style.scss";
import { Helmet } from "react-helmet";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";

import AOS from "aos";
import "aos/dist/aos.css";

library.add(fab);

export default function Layout({ children, location }) {
  useEffect(() => {
    import("bootstrap");

    AOS.init({
      //disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      disable: function () {
        return window.innerWidth <= 900;
      },
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 50, // values from 0 to 3000, with step 50ms
      duration: 600, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: true, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  });

  return (
    <div id="layout">
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/kzd2skt.css" />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#046a38" />
        <meta name="msapplication-TileColor" content="#046a38" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="google-site-verification"
          content="-74d5cJukqzdYD_XPZk8IDwc0pJFZIq2QjxF1wAnJ3E"
        />
      </Helmet>
      <Header location={location} />
      <main id="main">{children}</main>
      <Footer />
    </div>
  );
}
