import React, { useRef, useState, useEffect } from "react";
import formatWpMenu from "../../utilities/format-wp-menu";
import { useStaticQuery, graphql } from "gatsby";
import Link from "../utilities/link";
import CmsImage from "../utilities/cms-image";

const Header = ({ location }) => {
  const {
    wp: {
      themeSettings: { themeSettings },
    },
    primaryNav: {
      menuItems: { nodes: primaryNavLinks },
    },
  } = useStaticQuery(
    graphql`
      query Header {
        wp {
          themeSettings {
            themeSettings {
              logo {
                altText
                localFile {
                  extension
                  publicURL
                }
              }

              logoInverted {
                altText
                localFile {
                  extension
                  publicURL
                }
              }

              socialMedia {
                link
                icon {
                  altText
                  localFile {
                    extension
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
        primaryNav: wpMenu(slug: { eq: "primary-menu" }) {
          menuItems {
            nodes {
              id
              label
              path
              parentId
              target
              childItems {
                nodes {
                  id
                  label
                  url
                  path
                  childItems {
                    nodes {
                      id
                      label
                      url
                      path
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const primaryNav = formatWpMenu(primaryNavLinks),
    header = useRef(null);

  const toggleNavClass = () => {
    header.current.classList.toggle("show");
  };

  const toggleNav = (e) => {
    //e.preventDefault(); // This breaks external links.
    toggleNavClass();
  };

  const [scroll, setScroll] = useState(false);

  const handleWindowScroll = (e) => {
    setScroll(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);

    return () => {
      window.removeEventListener("scroll", handleWindowScroll);
    };
  });

  function getClassInnerLight() {
    return location.pathname === "/" ? "inner-light" : "";
  }

  function getLogoClass() {
    if (typeof window == "undefined") return "";

    return window.location.pathname !== "/"
      ? themeSettings.logoInverted
      : scroll
      ? themeSettings.logoInverted
      : themeSettings.logo;
  }

  return (
    <header
      className={`inner-header ${getClassInnerLight()} ${
        scroll ? "hasScrolled" : ""
      }`}
    >
      <nav className="navbar navbar-light">
        <div className="container web">
          <Link to="/" className="navbar-brand custom-logo-link">
            {getLogoClass() && <CmsImage image={getLogoClass()} />}
          </Link>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="sr-only"></span>
          </button>
          <div
            id="navbarNavDropdown"
            className="navbar-collapse collapse"
            ref={header}
          >
            <button
              className="navbar-toggler navbar-toggler-active collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="sr-only"></span>
            </button>
            <ul id="main-menu" className="navbar-nav ml-auto">
              {primaryNav !== null &&
                primaryNav.map((link) => (
                  <li key={link.id} className="nav-item">
                    <div
                      onClick={toggleNav}
                      onKeyDown={toggleNav}
                      role="button"
                      tabIndex={link.id}
                    >
                      <Link
                        className="nav-link"
                        activeClassName="active"
                        to={link.path}
                        target={link.target}
                      >
                        <span
                          className="link__layer"
                          data-text={link.label}
                        ></span>
                        {link.label}
                      </Link>
                    </div>
                  </li>
                ))}
              <li>
                <ul className="header-social">
                  {themeSettings.socialMedia &&
                    themeSettings.socialMedia.map((link, key) => (
                      <li key={key} className="pe-2">
                        <Link
                          to={link.link}
                          className="social-link"
                          target="_blank"
                        >
                          {link.icon && <CmsImage image={link.icon} />}
                        </Link>
                      </li>
                    ))}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
